import React from 'react';
import Home from '../views/Home/home';
import TermsOfService from '../views/TermsOfService/termsOfService';
import PrivacyPolicy from '../views/PrivacyPolicy/privacyPolicy';

import { Route, Switch, HashRouter, Redirect } from 'react-router-dom';


function Rotas(props) {

  return (
    <HashRouter>  
        <Switch>
        <Route exact path="/" component={Home} />  
        <Route exact path="/terms" component={TermsOfService} /> 
        <Route exact path="/policy" component={PrivacyPolicy} /> 
        </Switch> 
    </HashRouter>
  )
}

export default Rotas;
