import React from "react";
import './termsOfService.css'; // Importa o CSS

const TermsOfService = () => {
  return (
    <div className="container-principal">
      <h1>Termes et Conditions</h1>
      <p className="date">Date de mise à jour : 2024/10/18</p>

      <h2>1. Introduction</h2>
      <p>
        Bienvenue sur l'application <strong>Fleuriste Iracélia</strong>. En
        accédant ou en utilisant notre application, vous acceptez les présents
        termes et conditions. Si vous n'êtes pas d'accord avec l'une des
        dispositions énoncées dans ces termes, veuillez ne pas utiliser
        l'application.
      </p>

      <h2>2. Services offerts</h2>
      <p>
        <strong>Fleuriste Iracélia</strong> est une boutique en ligne qui
        permet aux utilisateurs d'acheter des bouquets de fleurs et de suivre le
        statut de leurs commandes. Les services incluent :
      </p>
      <ul>
        <li>La sélection et l'achat de bouquets de fleurs ;</li>
        <li>Le suivi en temps réel du statut de vos commandes ;</li>
        <li>Des notifications concernant la livraison et l'état de vos commandes.</li>
      </ul>

      <h2>3. Création d'un compte</h2>
      <p>
        Pour utiliser pleinement nos services, il est nécessaire de créer un
        compte sur l'application <strong>Fleuriste Iracélia</strong>. Lors de la
        création de votre compte, vous devrez fournir des informations exactes,
        actuelles et complètes. Vous êtes responsable de la sécurité de vos
        identifiants de connexion.
      </p>

      <h2>4. Commandes et paiements</h2>
      <p>
        Lorsque vous passez une commande, vous acceptez d'acheter le produit
        sélectionné conformément aux prix et aux conditions indiqués. Les
        paiements sont traités de manière sécurisée via nos fournisseurs
        partenaires. Les informations de paiement ne sont pas stockées sur nos
        serveurs.
      </p>

      <h2>5. Suivi des commandes</h2>
      <p>
        Après avoir passé une commande, vous pouvez suivre son statut dans la
        section dédiée de l'application. Les délais de livraison estimés peuvent
        varier en fonction de la destination et de la disponibilité des produits.
      </p>

      <h2>6. Utilisation acceptable</h2>
      <p>
        En utilisant notre application, vous vous engagez à ne pas :
      </p>
      <ul>
        <li>Utiliser l'application pour des activités illégales ou non autorisées ;</li>
        <li>Porter atteinte aux droits des autres utilisateurs ou de tierces parties ;</li>
        <li>Interférer avec le bon fonctionnement de l'application ou tenter de pirater les systèmes de sécurité.</li>
      </ul>

      <h2>7. Propriété intellectuelle</h2>
      <p>
        Tout le contenu, y compris mais sans s'y limiter, les textes, images,
        graphiques, logos, et autres éléments de l'application sont protégés par
        des droits d'auteur et sont la propriété de <strong>Fleuriste Iracélia</strong> 
        ou de ses concédants de licence. Il est strictement interdit de reproduire,
        distribuer ou modifier ces éléments sans autorisation préalable.
      </p>

      <h2>8. Limitation de responsabilité</h2>
      <p>
        Nous nous efforçons de fournir un service fiable et sécurisé, mais nous
        ne garantissons pas que l'application sera exempte d'erreurs ou
        d'interruptions. Nous ne serons pas responsables des pertes ou dommages
        résultant de l'utilisation ou de l'incapacité d'utiliser l'application.
      </p>

      <h2>9. Modifications des termes</h2>
      <p>
        Nous nous réservons le droit de modifier ces termes et conditions à tout
        moment. Toute modification sera affichée dans cette section et il est de
        votre responsabilité de les consulter régulièrement. Votre utilisation
        continue de l'application après la publication des modifications
        constituera votre acceptation des nouveaux termes.
      </p>

      <h2>10. Nous contacter</h2>
      <p>
        Si vous avez des questions concernant ces termes et conditions, veuillez
        nous contacter à l'adresse suivante : contact@fleuristeiracelia.com.
      </p>

      <footer>
        <p>Merci d'utiliser <strong>  L'application Fleuriste Iracélia</strong>.</p>
      </footer>
    </div>
  );
};

export default TermsOfService;
