import React from "react";
import './privacyPolicy.css'; // Importando o CSS para estilização

const PrivacyPolicy = () => {
  return (
    <div className="privacy-container">
      <h1>Politique de Confidentialité</h1>
      <p className="update-date">Dernière mise à jour : 2024-10-18</p>

      <h2>1. Introduction</h2>
      <p>
        Bienvenue sur l'application <strong>Fleuriste Iracélia</strong>. Cette
        politique de confidentialité explique comment nous collectons, utilisons
        et protégeons vos informations personnelles lorsque vous utilisez notre
        application. En utilisant notre application, vous acceptez les pratiques
        décrites dans cette politique.
      </p>

      <h2>2. Informations collectées</h2>
      <p>
        Nous collectons les informations suivantes lorsque vous utilisez
        l'application :
      </p>
      <ul>
        <li>
          <strong>Informations de compte :</strong> Votre nom, adresse e-mail,
          et informations de connexion si vous choisissez de vous inscrire via
          Google ou Apple.
        </li>
        <li>
          <strong>Informations de paiement :</strong> Pour les achats effectués
          via PayPal. Nous ne stockons pas vos informations de carte de crédit.
        </li>
        <li>
          <strong>Historique d'achat :</strong> Détails des commandes pour
          assurer la gestion des achats et des livraisons.
        </li>
      </ul>

      <h2>3. Utilisation des informations</h2>
      <p>Les informations collectées sont utilisées pour :</p>
      <ul>
        <li>Créer et gérer votre compte ;</li>
        <li>Traiter vos commandes et paiements ;</li>
        <li>Améliorer nos services et personnaliser votre expérience utilisateur.</li>
      </ul>

      <h2>4. Partage des informations</h2>
      <p>
        Nous ne partageons pas vos informations personnelles avec des tiers,
        sauf avec des fournisseurs de services comme PayPal, ou lorsque la loi
        l'exige.
      </p>

      <h2>5. Sécurité des données</h2>
      <p>
        Nous mettons en place des mesures de sécurité pour protéger vos données
        personnelles contre tout accès non autorisé.
      </p>

      <h2>6. Vos droits</h2>
      <p>
        Vous avez le droit d'accéder, de corriger ou de supprimer vos
        informations personnelles. Vous pouvez également fermer votre compte à
        tout moment.
      </p>

      <h2>7. Modifications de cette politique</h2>
      <p>
        Nous nous réservons le droit de modifier cette politique de
        confidentialité. Les modifications seront publiées sur cette page.
      </p>

      <footer>
        <p>
          Si vous avez des questions concernant cette politique de
          confidentialité, veuillez nous contacter à : contact@fleuristeiracelia.com .
        </p>
      </footer>
    </div>
  );
};

export default PrivacyPolicy;
