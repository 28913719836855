import React from 'react';
import Logo from '../../assets/logo.png';
import './home.css';
import { SocialIcon } from 'react-social-icons';

class Home extends React.Component {

    render() {
        return (
            <div className='container'>
                <div className="hero-image">
                    <img src={Logo} alt="Logo" className='logo' />
                    <p class="message">Site web en construction</p>
                    <p>Suivez-nous sur les réseaux sociaux</p>
                    <div className="flex flex-row">
                    <SocialIcon url="https://www.instagram.com/fleuristeiracelia/" />                    
                    <SocialIcon url="https://www.facebook.com/profile.php?id=61565928437445" />
                    </div>                 
                </div>            
            </div>            
        );
    }
}

export default Home;